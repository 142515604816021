import React from "react";
import { compose } from "recompose";
import { useIntl, defineMessages } from "react-intl";
import Form from "theme/components/atoms/Form/Form";
import { BodyFade } from "theme/components/atoms/Typography/Body";
import EnhanceLoginForm from "./EnhanceLoginForm";
import FormActions from "theme/components/molecules/Form/FormActions";
import { Email, Password, Checkbox } from "theme/components/atoms/Form/Input";
import SubmitButton from "theme/components/atoms/Button/SubmitButton";
import LoginMutation from "./LoginMutation.gql";
import Link from "theme/components/atoms/Typography/Link";
import { ErrorAlert } from "theme/components/molecules/Alert";
import Stack from "theme/components/atoms/Layout/Stack";
import AdditionalLoginFormActions from "theme/modules/User/LoginForm/AdditionalLoginFormActions";
import FlashMessages from "theme/modules/FlashMessages";

const messages = defineMessages({
  submit: {
    id: "modules.User.LoginForm.submit",
    defaultMessage: "Sign in",
  },
  emailLabel: {
    id: "modules.User.LoginForm.email.label",
    defaultMessage: "Email",
  },
  emailErrorRequired: {
    id: "modules.User.LoginForm.email.errorRequired",
    defaultMessage: "Please enter an email",
  },
  emailErrorMalformed: {
    id: "modules.User.LoginForm.email.errorMalformed",
    defaultMessage: "Please enter a valid email address",
  },
  passwordLabel: {
    id: "modules.User.LoginForm.password.label",
    defaultMessage: "Password",
  },
  passwordErrorRequired: {
    id: "modules.User.LoginForm.password.errorRequired",
    defaultMessage: "Please enter a password",
  },
  forgotPassword: {
    id: "modules.User.LoginForm.forgotPassword",
    defaultMessage: "Forgot your password?",
  },
  acceptTermOfUseErrorRequired: {
    id: "modules.User.LoginForm.acceptTermOfUseErrorRequired",
    defaultMessage: "Please accept terms of use",
  },
  infos: {
    id: "modules.User.LoginForm.infos",
    defaultMessage:
      "The information provided on the site (images, photos, technical descriptions technical descriptions, prices, evaluation of orders, quotations...) are not contractual and can change or evolve at any time. Only the characteristics and terms of the orders validated by the Everblue services will be taken into account.",
  },
  acceptTermsOfUse: {
    id: "modules.User.LoginForm.acceptTermsOfUse",
    defaultMessage: "I accept these terms of use",
  },
});

const LoginForm = (props) => {
  const { formatMessage } = useIntl();
  return (
    <Form
      onValidSubmit={(data) => props.authenticateUser(data)}
      onChange={props.onChange}
      className="login-form"
    >
      {props.errorMessage && (
        <Stack desktopSize="2" mobileSize="4">
          <ErrorAlert key="error">{props.errorMessage}</ErrorAlert>
        </Stack>
      )}
      <FlashMessages />
      <Stack desktopSize="1" mobileSize="2">
        <Stack key="form" desktopSize="1" mobileSize="2">
          <Email
            id="email"
            name="email"
            value={props.value?.email}
            required
            aria-label={formatMessage(messages.emailLabel)}
            placeholder={formatMessage(messages.emailLabel)}
            validationErrors={{
              required: formatMessage(messages.emailErrorRequired),
              isEmail: formatMessage(messages.emailErrorMalformed),
            }}
          />
          <Stack desktopSize="1" mobileSize="1">
            <Password
              id="password"
              name="password"
              required
              aria-label={formatMessage(messages.passwordLabel)}
              placeholder={formatMessage(messages.passwordLabel)}
              validationError={formatMessage(messages.passwordErrorRequired)}
            />
          </Stack>
        </Stack>
        <Stack size="1" desktopSize="1" mobileSize="1">
          <div className="login-form__terms-of-use">
            {formatMessage(messages.infos)}
          </div>
        </Stack>
        <Stack size="1" desktopSize="1" mobileSize="1">
          <div className="login-form__terms-of-use-checkbox">
            <Checkbox
              id="acceptTermsOfUse"
              name="acceptTermsOfUse"
              label={formatMessage(messages.acceptTermsOfUse)}
              validationError={formatMessage(
                messages.acceptTermOfUseErrorRequired
              )}
              required
            />
          </div>
        </Stack>
        <Stack key="actions" desktopSize="2" mobileSize="2">
          <FormActions appearance="center">
            <SubmitButton
              state={props.commandPending ? "pending" : undefined}
              appearance="login"
            >
              {formatMessage(messages.submit)}
            </SubmitButton>
            <AdditionalLoginFormActions />
          </FormActions>
          <FormActions appearance="center">
            <BodyFade>
              <strong className="auth-container__link">
                <Link to="/account/password-request">
                  {formatMessage(messages.forgotPassword)}
                </Link>
              </strong>
            </BodyFade>
          </FormActions>
        </Stack>
        {props.additionalActions}
      </Stack>
    </Form>
  );
};

export default compose(EnhanceLoginForm({ LoginMutation }))(LoginForm);
