import React from "react";

const AuthContainer = ({ title, children }) => {
  return (
    <div className="auth-container">
      <div className="auth-container__header">
        <div className="auth-container__header__title">{title}</div>
      </div>
      <div className="auth-container__form">{children}</div>
    </div>
  );
};

export default AuthContainer;
