import React from "react";
import { FormattedMessage } from "react-intl";
import compose from "recompose/compose";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import LoginForm from "theme/modules/User/LoginForm";
import withLoginRedirection from "./withLoginRedirection";
import checkAuth from "theme/modules/Router/checkAuth";
import qs from "web/core/utils/queryString";
import LoadingArea from "theme/components/molecules/LoadingArea/LoadingArea";
import { SuccessAlert } from "theme/components/molecules/Alert";
import withProps from "recompose/withProps";
import useRefreshing from "theme/modules/Router/useRefreshing";
import AuthContainer from "theme/modules/User/AuthContainer/AuthContainer";

const Login = (props) => {
  const search = qs.parse(props.location?.search ?? "");
  const [, setRefreshing] = useRefreshing();

  return (
    <div className="page-content page-content--simple">
      <div className="container">
        <AuthContainer
          title={
            <FormattedMessage id="pages.Login.title" defaultMessage="Sign in" />
          }
        >
          {props.location?.state?.from === "successfulPasswordReset" ? (
            <SuccessAlert>
              <FormattedMessage
                id="pages.Login.resetSuccess"
                defaultMessage="Your password was reset successfully"
              />
            </SuccessAlert>
          ) : null}
          <LoginForm
            key="login"
            onLoginSuccess={() => {
              // Temporary fix, waiting for more cache control on apollo
              setRefreshing();
              window.location.href = props.loginRedirectPath;
              props.clearLoginRedirectPath();
            }}
            value={{ email: search?.email }}
          />
        </AuthContainer>
      </div>
    </div>
  );
};

export default compose(
  withProps((props) => {
    const search = qs.parse(props.location?.search);
    return {
      redirectAfterLogin: search?.redirectTo,
    };
  }),
  checkAuth({
    condition: (me) => me && me.id === null,
    redirectLocation: (props) => props.redirectAfterLogin,
    LoadingComponent: () => {
      return (
        <div className="page-content page-content--simple">
          <div className="container">
            <LoadingArea>
              <FormattedMessage
                id="pages.Login.loading"
                defaultMessage="Loading..."
              />
            </LoadingArea>
          </div>
        </div>
      );
    },
  }),
  withLoginRedirection(
    typeof window !== "undefined" ? window : {},
    (props) => props.redirectAfterLogin
  ),
  withTrackPage("Login")
)(Login);
